var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltipText ? _vm.tooltipText : "",
          expression: "tooltipText ? tooltipText : ''",
        },
      ],
      class: [
        "switch",
        { "switch--on": _vm.value, "switch--disabled": _vm.disabled },
      ],
      on: { click: _vm.toggle },
    },
    [_c("div", { staticClass: "switch__thumb" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }