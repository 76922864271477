






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BaseSwitch extends Vue {
    @Prop({ type: Boolean, required: true }) public value!: boolean;

    @Prop({ type: Boolean, required: false, default: false }) public disabled!: boolean;

    @Prop({ type: [String, Number], required: false, default: '' }) public tooltipText!: string | number;

    private toggle (): void {
        if (!this.disabled) {
            this.$emit('input', !this.value);
        }
    }
}
