












































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseModal from '@/components/BaseModal.vue';
import ButtonMakePrimary from '@/components/HierarchyFunctions/ButtonMakePrimary.vue';
import BenchmarkRules from '@/components/HierarchyFunctions/BenchmarkRules.vue';
import CustomInvestmentExposures from '@/components/HierarchyFunctions/CustomInvestmentExposures.vue';
import TickerDetails from '@/components/TickerDetails.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { IAllocation, IClientEntity, ITickerDetails, IBenchmark, IRollForward } from '@/interfaces/entity/IClientEntity.js';
import { IFundLatestGradeDto } from '@/interfaces/dto/IFundLatestGradeDto';
import { groupBy } from '@/utils/groupBy';
import { TTickerType } from '@/types/TTickerType';
import EntityDataApi from '@/api/entityDataApi';
import VTooltip from 'v-tooltip';
import { IRollForwardDto } from '@/interfaces/dto/IRollForwardDto';
import { IBenchmarkDto } from '@/interfaces/dto/IBenchmarkDto';
import { IBenchmarkRule } from '@/interfaces/entity/IBenchmarkRule';
import { TEntityTypeDto } from '@/interfaces/dto/IEntityTypeDto';
import BaseButton from '@/components/BaseElements/BaseButton.vue';
import BaseSwitch from '@/components/BaseElements/BaseSwitch.vue';
import BaseDivider from '@/components/BaseElements/BaseDivider.vue';
import { ISaveNameDto } from '@/interfaces/dto/ISaveNameDto';
import { IUpdateEntityManagerMappingsRequestDto } from '@/interfaces/dto/IUpdateEntityManagerMappingsRequestDto';
import { IUpdateEntityAllocationsRequestDto } from '@/interfaces/dto/IUpdateEntityAllocationsRequestDto';
Vue.use(VTooltip);

@Component({
    components: {
        BaseModal,
        TickerDetails,
        LoadingOverlay,
        ButtonMakePrimary,
        BenchmarkRules,
        BaseButton,
        CustomInvestmentExposures,
        BaseDivider,
        BaseSwitch
    },
})
export default class EditEntity extends Vue {
    @Prop({ type:Object, required:true }) editingNodeObj: IClientEntity;

    @Prop({ type: Number || null, required: true }) portfolioId: number | null;

    @Prop({ type: Array, required: true }) entityNames: string[];

    @Prop({ type: Array, required: true }) selectedNodeExposureEntities: IClientEntity[];

    get editingNodeEntityType (): TEntityTypeDto | null {
        return this.editingNode?.entityTypeId ?? null;
    }

    get hasSecondaryBenchmark (): boolean {
        return this.editingNode.benchmarks.some((b: IBenchmark) => !b.isPrimaryBenchmark);
    }

    public editingNode: IClientEntity | null = null;

    public editingType: TTickerType | null = null;

    public benchmarkEditId: number | null = null

    public benchmarkData: IBenchmark | null = null;

    public rollForwardData: IRollForward | null = null;

    public showError = false;

    public showTickerIdSearch = false;

    public showManagerResearch = false;

    public editName = false;

    public isLoading = false;


    get isSumWeightNode (): boolean {
        return this.isEntityGrouping || this.isEntityPortfolio || this.isEntityCustomInvestment;
    }

    get benchmarkCount (): number {
        // sum weighted benchmark is add if Node or Root is open
        return this.editingNode.benchmarks.length + (this.isEntityGrouping || this.isEntityPortfolio ? 1 : 0);
    }

    get secondaryBenchmarksCount (): number {
        return this.editingNode.benchmarks.filter((b: IBenchmark) => !b.isPrimaryBenchmark).length;
    }

    get nameLabel ():string {
        if (this.isEntityGrouping) return 'Grouping name';
        if (this.isEntityStandardInvestment) return 'Standard investment name';
        if (this.isEntityCustomInvestment) return 'Custom investment name';
        if (this.isEntityExposure) return 'Exposure name';
        return 'Name';
    }


    get showRollForward (): boolean {
        return  !this.isEntityGrouping && !this.isEntityPortfolio && !this.isNewEntity && !this.isEntityCustomInvestment;
    }

    get showBenchmarkRules (): boolean {
        return !this.isNewEntity;
    }

    get showStrategicAllocation (): boolean {
        return (this.isEntityStandardInvestment || this.isEntityExposure) && !this.isNewEntity;
    }

    get showResearchView (): boolean {
        return (this.isEntityStandardInvestment || this.isEntityExposure) && !this.isNewEntity;
    }

    get showExposures (): boolean {
        return this.isEntityCustomInvestment && !this.isNewEntity;
    }

    get isEntityPortfolio (): boolean {
        return this.editingNodeObj.entityTypeId === 2;
    }

    get isEntityGrouping (): boolean {
        return this.editingNodeObj.entityTypeId === 4;
    }

    get isEntityStandardInvestment (): boolean {
        return this.editingNodeObj.entityTypeId === 5;
    }

    get isEntityCustomInvestment (): boolean {
        return this.editingNodeObj.entityTypeId === 6;
    }

    get isEntityExposure (): boolean {
        return this.editingNodeObj.entityTypeId === 7;
    }

    get isSumWeightedPrimaryBenchmark (): boolean {
        return (this.isEntityGrouping || this.isEntityPortfolio) && this.editingNode.benchmarks.every((b: IBenchmark) => b.isPrimaryBenchmark === false);
    }

    get canUseDynamicOverrides (): boolean {
        if (process.env.VUE_APP_FEATURE_DYNAMIC_OVERRIDES !== 'true') {
            return false;
        }

        return this.isEntityStandardInvestment || this.isEntityExposure;
    }

    sortByDate (a: ITickerDetails, b: ITickerDetails): number {
        return dayjs(b.effectiveDate).valueOf() - dayjs(a.effectiveDate).valueOf();
    }

    benchmarkRules: Array<IBenchmarkRule>| null = []

    // return benchmarks, entityTypeId and id
    calcBenchmarkRules (): Array<IBenchmarkRule>| null {
        if (this.editingNode === null) return null;
        const benchmarks:   Array<IBenchmarkRule>= [];
        const clonedBenchmarks = cloneDeep(this.editingNode.benchmarks);
        const isSumWeightedPrimaryBenchmark = clonedBenchmarks.every((b: IBenchmark) => b.isPrimaryBenchmark === false);
        if (this.isSumWeightNode && isSumWeightedPrimaryBenchmark) {
            benchmarks.push({
                benchmarkId: null,
                benchmarkName: 'Sum-weighted',
                entityNameId: null,
                isPrimaryBenchmark: true,
                benchmarkPieces: [],
                benchmarkLabel: this.calcBenchmarkRuleLabel({
                    benchmarkId: null,
                    benchmarkName: 'Sum-weighted',
                    entityNameId: null,
                    id: this.editingNode.id,
                    isPrimaryBenchmark: true,
                    benchmarkPieces: [],
                    useDynamicOverrides: false,
                }),
                useDynamicOverrides: false,
            });
        }
        clonedBenchmarks.forEach((b: IBenchmark) => {
            benchmarks.push({
                benchmarkId: b.benchmarkId,
                benchmarkName: b.benchmarkName,
                entityNameId: b.entityNameId,
                isPrimaryBenchmark: b.isPrimaryBenchmark,
                benchmarkPieces: this.groupAndSortBenchmarkPieces(b.benchmarkPieces),
                benchmarkLabel: this.calcBenchmarkRuleLabel(b),
                useDynamicOverrides: b.useDynamicOverrides,
            });
        });
        if (this.isSumWeightNode && !isSumWeightedPrimaryBenchmark) {
            benchmarks.push({
                benchmarkId: null,
                benchmarkName: 'Sum-weighted',
                entityNameId: null,
                isPrimaryBenchmark: false,
                benchmarkPieces: [],
                benchmarkLabel: this.calcBenchmarkRuleLabel({
                    benchmarkId: null,
                    benchmarkName: 'Sum-weighted',
                    entityNameId: null,
                    id: this.editingNode.id,
                    isPrimaryBenchmark: false,
                    benchmarkPieces: [],
                    useDynamicOverrides: false,
                }),
                useDynamicOverrides: false,
            });
        }

        this.benchmarkRules = benchmarks ?? null;
    }


    // group benchmark pieces by effective date and sort by date, newest first
    groupAndSortBenchmarkPieces (benchmarkPieces: Array<ITickerDetails>):  Array<ITickerDetails>[] {
        const grouped = groupBy(benchmarkPieces, t => t.effectiveDate);
        const sorted = Object.keys(grouped).sort((a: string, b: string) => {
            return dayjs(b).valueOf() - dayjs(a).valueOf();
        });
        const sortedBenchmarkPieces: Array<ITickerDetails>[] = [];
        sorted.forEach((key: string) => {
            sortedBenchmarkPieces.push(grouped[key]);
        });
        return sortedBenchmarkPieces;
    }

    alternativeBenchmarkRuleCounter = 0;

    calcBenchmarkRuleLabel (bm: IBenchmark): string {
        let bmType = '';
        if (bm.isPrimaryBenchmark) bmType = 'PRIMARY';
        else {
            this.alternativeBenchmarkRuleCounter += 1;
            bmType = `ALTERNATIVE ${this.alternativeBenchmarkRuleCounter}`;
        }
        return bmType;
    }

    openExposureById (exposureId: number | null): void {
        if (exposureId === null) this.$emit('create-exposure');
        else this.$emit('open-exposure-by-id', exposureId);
    }

    deleteExposureById (exposureId: number): void {
        this.$emit('delete-exposure-by-id', exposureId);
    }


    openBenchmarkById (benchmarkId: number | null): void {
        this.showBenchmarkModal(benchmarkId);
    }

    async deleteBenchmarkById (benchmarkId: number | null): Promise<void> {
        if (!this.editingNode || benchmarkId === null) return;
        this.isBenchmarkRuleAPIPending = true;

        this.editingType = null;
        const payload = {
            entityNameId: this.editingNode.id,
            benchmarkId: benchmarkId,
        };
        try {
            const newEntity: IClientEntity = await EntityDataApi.deleteBenchmark(payload);
            this.editingNode.benchmarks = newEntity.benchmarks;
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'There was an error deleting the benchmark. Please try again.');
        } finally {
            this.isBenchmarkRuleAPIPending = false;

        }

        this.benchmarkEditId = null;

    }

    isBenchmarkRuleAPIPending = false;

    async makePrimaryById (benchmarkId: number|null): Promise<void> {
        if (benchmarkId === null) {
            // make sum-weighted primary
            this.editingNode.benchmarks.forEach((b: IBenchmark) => {
                b.isPrimaryBenchmark = false;
            });
        }
        else {
            //make the benchmark id primary
            this.editingNode.benchmarks.forEach((b: IBenchmark) => {
                b.isPrimaryBenchmark = b.benchmarkId === benchmarkId;
            });
        }

        let payload: IBenchmarkDto = {
            entityNameId: this.editingNode.id,
            benchmarks: this.editingNode.benchmarks,
        };
        this.editingType = null;
        this.isBenchmarkRuleAPIPending = true;
        await this.saveBenchmarks(payload);
        this.isBenchmarkRuleAPIPending = false;
    }

    async toggleDynamicOverridesById (benchmarkId: number|null): Promise<void> {
        const benchmark = this.editingNode.benchmarks.find((b: IBenchmark) => b.benchmarkId === benchmarkId);

        if (!benchmark) {
            return;
        }

        benchmark.useDynamicOverrides = !benchmark.useDynamicOverrides;

        let payload: IBenchmarkDto = {
            entityNameId: this.editingNode.id,
            benchmarks: this.editingNode.benchmarks,
        };
        this.editingType = null;
        this.isBenchmarkRuleAPIPending = true;
        await this.saveBenchmarks(payload);
        this.isBenchmarkRuleAPIPending = false;
    }

    async toggleDynamicOverridesForRollForward (): Promise<void> {
        if (!this.editingNode.rollForwards) {
            return;
        }

        this.editingNode.rollForwards.useDynamicOverrides = !this.editingNode.rollForwards.useDynamicOverrides;

        this.isLoading = true;
        await this.saveRollForwardChanges();
        this.isLoading = false;
    }

    get useDynamicOverridesForRollForward (): boolean {
        return this.editingNode.rollForwards?.useDynamicOverrides;
    }

    get dynamicOverridesTooltipText (): string {
        return this.useDynamicOverridesForRollForward ? 'Click to disable dynamic overrides' : 'Click to enable dynamic overrides';
    }

    created (): void {
        this.editingNode = cloneDeep(this.editingNodeObj);
        this.editName = this.editingNode.id === null;
    }

    get showResearchText (): boolean {
        return !!this.selectedManagerFund;
    }

    get isNewEntity (): boolean {
        return this.editingNode.id === null;
    }

    get showManagerResearchLink (): boolean {
        if (this.editingNode.researchView?.researchEntityId === undefined) return false;
        return true;
    }

    get managerResearchLink (): string {
        if (!this.selectedManagerFund) return '';
        return `${process.env.VUE_APP_INVESTMENT_RESEARCH_URL}/fund/${this.selectedManagerFund.fundInfo.fundId}`;
    }

    get selectedManagerFund (): IFundLatestGradeDto | null {
        if (!this.editingNode.researchView) return null;

        return this.$store.state.fundsWithLatestGrade.find((mf: IFundLatestGradeDto) => mf.fundInfo.fundResearchEntityId === this.editingNode.researchView.researchEntityId);
    }

    hideManagerModal (): void {
        this.editingType = null;
        this.showManagerResearch = false;
    }

    async saveManagerAssociation (selectedManager: IFundLatestGradeDto | null): Promise<void> {
        this.isLoading = true;
        if (selectedManager && selectedManager.fundInfo.fundResearchEntityId && this.editingNode.researchView) {
            Vue.set(this.editingNode.researchView, 'researchEntityId', selectedManager.fundInfo.fundResearchEntityId);
            Vue.set(this.editingNode, 'researchEntityId', selectedManager.fundInfo.fundResearchEntityId);
        } else if (selectedManager) {
            Vue.set(this.editingNode, 'researchEntityId', selectedManager.fundInfo.fundResearchEntityId);
            Vue.set(this.editingNode, 'researchView', {
                researchEntityId: selectedManager.fundInfo.fundResearchEntityId,
                approvalStatus: selectedManager.latestGrade?.approvalStatus ?? null,
                responsibleInvestmentGradeNew: selectedManager.latestGrade?.overallGrade ?? 0,
            });
        }

        const payload = {
            entityNameId: this.editingNode.id,
            researchEntityId: selectedManager? selectedManager.fundInfo.fundResearchEntityId : -1,
        };

        this.editingType = null;
        this.showManagerResearch = false;
        await EntityDataApi.saveManagerMapping(payload);
        this.isLoading = false;
    }

    async deleteAssociation (): Promise<void> {
        const payload: IUpdateEntityManagerMappingsRequestDto = {
            entityNameId: this.editingNode.id,
            researchEntityId: -1, // -1 is the default value for no association
        };

        this.isLoading = true;
        this.editingType = null;
        this.showManagerResearch = false;
        try {
            await EntityDataApi.saveManagerMapping(payload);
            Vue.set(this.editingNode, 'researchView', null);
        } catch (error) {
            this.$store.dispatch('pushNetworkErrorMessage', 'There was an error deleting the manager association. Please try again.');
        } finally {
            this.isLoading = false;
        }

    }

    showBenchmarkModal (benchmarkId: number): void {
        this.editingType = 'benchmark';
        this.benchmarkEditId = benchmarkId;
        this.benchmarkData = this.getBenchmarkData();
    }

    openFundingNotional (): void {
        this.showTickersModal('rollForward');
    }

    showTickersModal (tickerName: TTickerType): void {
        this.editingType = tickerName;

        if (tickerName === 'entityManagerMapping') {
            this.showManagerResearch = true;
        }

        if (tickerName === 'rollForward') {
            this.rollForwardData = this.getRollForwardData();
        }
    }

   nameError = '';

   isEntityNameValid (): boolean {
       this.nameError = '';
       if (!this.editingNode) {
           this.nameError= 'Entity not valid';
       }

       const name = this.editingNode.name.trim();
       if (name === '') {
           this.nameError = 'Name is required';
           return false;
       };
       if (name.length < 3) {
           this.nameError = 'Name must be at least 3 characters long';
           return false;
       };


       const isEntityNameDuplicate = this.entityNames.some((entityName: string) => {
           return entityName.trim().toLowerCase() === name.toLowerCase();
       });
       if (isEntityNameDuplicate) {
           this.nameError = 'Name already exists';
           return false;
       };

       return true;
   }

   get editRollForwardText (): string {
       if (this.editingNode.rollForwards?.benchmarkPieces.length) {
           const data = this.editingNode.rollForwards?.benchmarkPieces.sort((a: ITickerDetails, b: ITickerDetails) => {
               return dayjs(b.effectiveDate).valueOf() - dayjs(a.effectiveDate).valueOf();
           });

           const grouped = groupBy(data, t => t.effectiveDate);
           let s = '';

           grouped[data[0].effectiveDate].forEach((e) => {
               s += `${Math.round(e.weight * 100)}% ${e.tickerId} `;
           });

           const totalRecords = Object.keys(grouped).length;

           let text = '';

           if (totalRecords === 2) {
               text = `& ${totalRecords - 1} historical record`;
           } else if (totalRecords > 2) {
               text = `& ${totalRecords - 1} historical records`;
           }

           return s + `from ${dayjs(data[0].effectiveDate).format('D/M/YYYY')} ${text}`;
       } else {
           return 'not set';
       }
   }

   @Watch('editingNode.benchmarks')
   onBenchmarksChange (): void {
       this.alternativeBenchmarkRuleCounter = 0;
       this.calcBenchmarkRules();
   }


   get editAllocationText (): string {
       if (this.editingNode.strategicAllocations.length) {
           const data = this.editingNode.strategicAllocations.sort((a: IAllocation, b: IAllocation) => {
               return dayjs(b.effectiveDate).valueOf() - dayjs(a.effectiveDate).valueOf();
           });

           let s = `${Math.round(data[0].allocationValue * 1000) / 10}%`;

           const totalRecords = this.editingNode.strategicAllocations.length;

           let text = '';
           if (totalRecords === 2) {
               text = `& ${totalRecords - 1} historical record`;
           } else if (totalRecords > 2) {
               text = `& ${totalRecords - 1} historical records`;
           }

           return s + ` from ${dayjs(data[0].effectiveDate).format('D/M/YYYY')} ${text}`;
       } else {
           return 'not set';
       }
   }

   get tickersDetailsData (): Array<ITickerDetails> | Array<IAllocation> {
       if (this.editingType === 'benchmark') {
           const tickerIndex = this.editingNode.benchmarks.findIndex((b: IBenchmark) => b.benchmarkId === this.benchmarkEditId);
           if (tickerIndex === -1) return [];
           return this.editingNode.benchmarks[tickerIndex].benchmarkPieces ?? [];
       }
       if (this.editingType === 'rollForward') return this.editingNode.rollForwards?.benchmarkPieces ?? [];
       if (this.editingType === 'strategicAllocation') return this.editingNode.strategicAllocations;
       return [];
   }

   get isThereAPrimaryBenchmark (): boolean {
       const isPrimaryBenchmark =  this.editingNode.benchmarks.some((b: IBenchmark) => b.isPrimaryBenchmark);
       if (this.isSumWeightNode && !isPrimaryBenchmark) {
           return true; // sum-weight will be "primary"
       }
       return isPrimaryBenchmark;
   }

   getBenchmarkData (): IBenchmark | null {
       if (this.editingType === 'benchmark') {
           const tickerIndex = this.editingNode.benchmarks.findIndex((b: IBenchmark) => b.benchmarkId === this.benchmarkEditId);
           if (this.benchmarkEditId ===  null || tickerIndex === -1) return {
               benchmarkId: null,
               benchmarkName: 'benchmark',
               isPrimaryBenchmark: !this.isThereAPrimaryBenchmark,
               benchmarkPieces: [],
               entityNameId: null,
               id: null,
               useDynamicOverrides: false,
           };
           return cloneDeep(this.editingNode.benchmarks[tickerIndex]);
       } else {
           return null;
       }
   }

   getRollForwardData (): IRollForward | null {
       if (this.editingType === 'rollForward') {
           if (this.editingNode.rollForwards === null) {
               return {
                   benchmarkId: null,
                   benchmarkName: 'rollforward',
                   benchmarkPieces: [],
                   entityNameId: null,
                   id: null,
                   useDynamicOverrides: false,
               };
           }
           return cloneDeep(this.editingNode.rollForwards);
       } else {
           return null;
       }
   }

   public discardTickerChanges (): void {
       this.editingType = null;
   }

   async saveAllocationChanges (data: Array<IAllocation>): Promise<void> {
       if (!this.editingNode) return;
       this.isLoading = true;
       if (this.editingType === 'strategicAllocation') {
           this.editingNode.strategicAllocations = data;
       }

       const payload: IUpdateEntityAllocationsRequestDto = {
           entityNameId: this.editingNode.id,
           allocations: data,
       };

       this.editingType = null;
       try {
           await EntityDataApi.updateEntityAllocations(payload);
       } catch (error) {
           this.$store.dispatch('pushNetworkErrorMessage', 'There was an error saving the strategic allocation. Please try again.');
       } finally {
           this.isLoading = false;
       }
   }

   public async deleteStrategicAllocation (): Promise<void> {
       if (!this.editingNode) return;
       this.isLoading = true;
       this.editingType = null;
       const payload: IUpdateEntityAllocationsRequestDto = {
           entityNameId: this.editingNode.id,
           allocations: [],
       };
       try {
           const response = await EntityDataApi.updateEntityAllocations(payload);
           this.editingNode.strategicAllocations = response.strategicAllocations;
       } catch (error) {
           this.$store.dispatch('pushNetworkErrorMessage', 'There was an error deleting the strategic allocation. Please try again.');
       } finally {
           this.isLoading = false;
       }
       this.benchmarkEditId = null;

   }

   public async deleteRollForward (): Promise<void>{

       if (!this.editingNode) return;
       const payload = {
           entityNameId: this.editingNode.id,
           benchmarkId: this.rollForwardData?.benchmarkId,
       };
       try {
           this.isLoading = true;
           this.editingType = null;
           const newEntity: IClientEntity = await EntityDataApi.deleteRollForward(payload);
           this.editingNode.rollForwards = newEntity.rollForwards;
       } catch (error) {
           this.$store.dispatch('pushNetworkErrorMessage', 'There was an error deleting the rollforward. Please try again.');
       } finally {
           this.isLoading = false;
       }

       this.benchmarkEditId = null;

   }

   public async deleteBenchmark (): Promise<void> {

       if (!this.editingNode) return;
       const payload = {
           entityNameId: this.editingNode.id,
           benchmarkId: this.benchmarkData?.benchmarkId,
       };
       try {
           this.isLoading = true;
           this.editingType = null;
           const newEntity: IClientEntity = await EntityDataApi.deleteBenchmark(payload);
           this.editingNode.benchmarks = newEntity.benchmarks;
       } catch (error) {
           this.$store.dispatch('pushNetworkErrorMessage', 'There was an error deleting the benchmark. Please try again.');
       } finally {
           this.isLoading = false;

       }
       this.benchmarkEditId = null;
   }

   async saveBenchmarks (payload: IBenchmarkDto): Promise<void> {

       try {
           const response = await EntityDataApi.saveBenchmarks(payload);
           this.editingNode.benchmarks = response.benchmarks.sort(b => {
               return b.isPrimaryBenchmark ? -1 : 1;
           });
           this.benchmarkEditId = null;
       } catch (error) {
           this.editingNode = cloneDeep(this.editingNodeObj);
           this.editName = this.editingNode.id === null;
           this.$store.dispatch('pushNetworkErrorMessage', 'There was an error saving the benchmark. Please try again.');
       } finally {
           this.isLoading = false;
           this.isBenchmarkRuleAPIPending = false;

       }

   }

   public async saveBenchmarkChanges (bm: IBenchmark): Promise<void> {
       if (!this.editingNode) return;
       this.isLoading = true;
       this.isBenchmarkRuleAPIPending = true;

       if (bm.isPrimaryBenchmark) {
           this.editingNode.benchmarks.forEach((b: IBenchmark) => {
               b.isPrimaryBenchmark = false;
           });
       }

       if (this.benchmarkEditId != null) {
           const index = this.editingNode.benchmarks.findIndex((b: IBenchmark) => b.benchmarkId === this.benchmarkEditId);
           this.editingNode.benchmarks.splice(index, 1);
       }

       this.editingNode.benchmarks.push(cloneDeep(bm));

       let payload: IBenchmarkDto = {
           entityNameId: this.editingNode.id,
           benchmarks: this.editingNode.benchmarks,
       };
       this.editingType = null;

       await this.saveBenchmarks(payload);
   }

   public async saveRollForwardChangesWithTickerDetails (data: Array<ITickerDetails>): Promise<void> {
       if (!this.editingNode) return;
       this.isLoading = true;

       if (this.editingNode.rollForwards == null) {
           this.editingNode.rollForwards = {
               benchmarkId: null,
               benchmarkName: 'rollforward',
               benchmarkPieces: data,
               entityNameId: null,
               id: null,
               useDynamicOverrides: false
           };
       } else {
           this.editingNode.rollForwards.benchmarkPieces = data;
       }

       this.saveRollForwardChanges();
   }

   public async saveRollForwardChanges (): Promise<void> {
       if (!this.editingNode) return;
       this.isLoading = true;

       let payload: IRollForwardDto = {
           entityNameId: this.editingNode.id,
           rollForward: this.editingNode.rollForwards,
       };

       this.editingType = null;
       try {
           const response = await EntityDataApi.saveRollForward(payload);
           this.editingNode.rollForwards = response.rollForwards;
       } catch (error) {
           this.editingNode = cloneDeep(this.editingNodeObj);
           this.editName = this.editingNode.id === null;
           this.$store.dispatch('pushNetworkErrorMessage', 'There was an error saving the rollforward. Please try again.');
       } finally {
           this.isLoading = false;
       }
   }

   cancelName ():void{
       this.editName = false;
       this.editingNode.name = this.editingNodeObj.name;
   }

   handleNameChange (): void {
       if (!this.editingNode.id) this.saveNodeData(); // if new entity, save it
       else this.saveName(); // if existing entity, save name
   }

   async saveName (): Promise<void>  {
       if (!this.isEntityNameValid()) {
           this.showError = true;
           this.$nextTick(() => {
               (this.$refs.entityName as HTMLInputElement).focus();
           });
           return;
       } else {
           this.showError = false;
       }
       this.isLoading = true;
       const payload: ISaveNameDto ={
           entityNameId: this.editingNode.id,
           entityName: this.editingNode.name.trim()
       };

       try {
           await EntityDataApi.saveName(payload);
           this.$emit('update-entity', this.editingNode);
       } catch (error) {
           this.editingNode = cloneDeep(this.editingNodeObj);
           this.$store.dispatch('pushNetworkErrorMessage', 'There was an error saving the name. Please try again.');
       } finally {
           this.editName = false;
           this.isLoading = false;
       }
   }

   public saveNodeData (): void {
       if (!this.isEntityNameValid()) {
           this.showError = true;
           this.$nextTick(() => {
               (this.$refs.entityName as HTMLInputElement).focus();
           });
           return;
       } else {
           this.showError = false;
       }
       this.$emit('save-new', this.editingNode);
       this.editName = false;
       this.editingType = null;
   }

   public updateShowTickerIdSearch (newValue: boolean): void {
       this.showTickerIdSearch = newValue;
   }
}
